<template>
  <div id="exchageDialog">
    <transition name="show-dialog">
      <div class="mask" v-if="isShow">
        <div class="dialogWrap" :class="{ disable: isShowArea }">
          <p class="title">领取商品</p>
          <div class="dialogScroll">
            <div class="goodsWrap" v-if="!!goods">
              <img
                class="goodsImg"
                :src="
                  goods.spuSnapshotGallery && goods.spuSnapshotGallery.imgUrl
                "
                :alt="goods.name || ''"
                v-if="
                  goods.spuSnapshotGallery && goods.spuSnapshotGallery.imgUrl
                "
              />
              <div class="goodsImg noImg" v-else></div>
              <p class="goodsName">{{ goods.name || "" }}</p>
            </div>
            <div class="goodsParams" v-if="skuList.length">
              <span
                class="aGoodsParam"
                :class="{ cur: item.skuId == selectSkuId }"
                @click="selectSku(item.skuId)"
                v-for="(item, index) in skuList"
                :key="'skuList' + index"
                >{{ item.skuName }}</span
              >
            </div>
            <van-loading v-if="loading">加载中...</van-loading>
            <p class="title">物流信息</p>
            <div class="infoForm">
              <div class="infoFormItem">
                <span class="txt">联系人</span>
                <div class="inputWrap">
                  <input
                    type="text"
                    class="fromInput"
                    v-model="order.name"
                    :disabled="!skuList.length"
                  />
                </div>
              </div>
              <div class="infoFormItem">
                <span class="txt">联系电话</span>
                <div class="inputWrap">
                  <input
                    type="tel"
                    maxlength="13"
                    class="fromInput"
                    v-model="order.mobile"
                    :disabled="!skuList.length"
                  />
                </div>
              </div>
              <div class="infoFormItem">
                <span class="txt">地址</span>
                <div class="inputWrap">
                  <div
                    class="addrWrap"
                    :class="{ disabled: !skuList.length }"
                    @click="showAreaLayer"
                  >
                    <span
                      class="addr"
                      :class="{ hastxt: !!order.provinceName }"
                      >{{ order.provinceName || "省" }}</span
                    >
                    <span class="addr" :class="{ hastxt: !!order.cityName }">{{
                      order.cityName || "市"
                    }}</span>
                    <span
                      class="addr"
                      :class="{ hastxt: !!order.districtName }"
                      >{{ order.districtName || "区" }}</span
                    >
                  </div>
                  <input
                    type="text"
                    class="fromInput"
                    placeholder="详细地址"
                    v-model="order.detailAddress"
                    :disabled="!skuList.length"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="btns">
            <button
              class="btn main"
              type="button"
              :disabled="!skuList.length || submiting"
              @click="confirmDialog"
            >
              <van-loading color="#fff" v-if="submiting" />
              确认领取
            </button>
            <button class="btn" type="button" @click="closeDialog(0)">
              取消
            </button>
          </div>
        </div>
        <areaLayer
          :showSelect="isShowArea"
          :selectArea="selectAreaList"
          @close="closeAreaLayer"
        ></areaLayer>
      </div>
    </transition>
  </div>
</template>

<script>
import areaLayer from "@/components/member/areaLayer";

export default {
  name: "exchageDialog",
  props: ["isDialogShow", "goods", "sceneNo"],
  components: {
    areaLayer
  },
  data() {
    return {
      loading: false,
      isShow: false,
      submiting: false,
      isShowArea: false, //  显示地区
      selectAreaList: [], //  已经选择的地区
      order: {
        isDefault: 0, //  是否默认收货地址
        name: "", //  收货人姓名
        mobile: "", //  收货手机号
        provinceId: null, //  省id
        cityId: null, //  市id
        districtId: null, // 区id
        provinceName: "", //  省文字
        cityName: "", // 市文字
        districtName: "", /// 区文字
        detailAddress: "" //  详细收货地址信息
      }, //  表单
      selectAreaTxt: "",
      selectSkuId: "", //  当前sku选择
      skuList: [],
      goodsStore: {}, //  商品信息临时存储， 减少接口请求
      verifyArr: ["name", "mobile", "area", "detailAddress"] //  验证字段
    };
  },
  watch: {
    isDialogShow(val) {
      this.isShow = val;
      if (!val) {
        this.selectSkuId = "";
        this.skuList = [];
      } else {
        this.loading = true;
        this.getGoodsDetail();
      }
    }
  },
  created() {
    if (!!localStorage.getItem("exUserAddr")) {
      this.order = JSON.parse(localStorage.getItem("exUserAddr"));
      this.selectAreaList = [
        this.order.provinceId,
        this.order.cityId,
        this.order.districtId
      ];
    }
  },
  methods: {
    //  确认弹框
    async confirmDialog() {
      if (this.submiting || this.loading) return;
      //  验证
      for (let i = 0; i < this.verifyArr.length; i++) {
        let checkTips = this.checkInput(this.verifyArr[i]);
        if (!!checkTips) {
          this.$toast({
            message: checkTips,
            position: "bottom"
          });
          return;
        }
      }
      //  对接接口
      this.submiting = true;
      try {
        //  保存地址
        let addrRes = await this.request.post(
          "/api/scm/user/address/save",
          this.order
        );
        if (!!addrRes) {
          //   记录用户填写并保存的地址
          localStorage.setItem("exUserAddr", JSON.stringify(this.order));
          let uid = addrRes.uid,
            addId = addrRes.id;
          let orderId = this.global.createOrderId(uid, this.selectSkuId);

          let orderRes = await this.request.post("/api/scm/order/create", {
            bizId: orderId,
            sceneNo: this.sceneNo,
            placeSkuList: [{ skuId: this.selectSkuId, placeNum: 1 }],
            userAddressId: addId
          });
          if (!!orderRes) {
            //  价格一定要为0
            if (!orderRes.actualAmount) {
              let payBizId =
                orderId + String(new Date().getTime()).substr(-4, 4);
              let payRes = await this.request.postFrom(
                `/api/scm/pay/order?bizId=${orderId}&payType=1&payBizId=${payBizId}`,
                {}
              );
              if (!!payRes) {
                this.$toast({
                  message: "兑换成功",
                  position: "bottom"
                });
                this.closeDialog(1);
              }
              this.submiting = false;
            } else {
              this.$toast({
                message: "您好像没有兑换此商品的资格",
                position: "bottom"
              });
              this.submiting = false;
            }
          } else {
            this.submiting = false;
          }
        } else {
          this.submiting = false;
        }
      } catch (e) {
        console.error(e);
        this.submiting = false;
      }
    },
    //  关闭弹框
    closeDialog(type) {
      if (type && this.goods.spuId) {
        this.goodsStore[this.goods.spuId] = null;
      }
      this.$emit("close", true);
    },
    //  显示地址栏
    showAreaLayer() {
      if (!!this.skuList.length) this.isShowArea = true;
    },
    //  关闭选择地区
    closeAreaLayer(val) {
      if (!!val) {
        let txt = "",
          tempArr = [];
        val.forEach((item) => {
          txt += item.text + " ";
          tempArr.push(item.id);
        });
        this.areaTxt = txt;
        this.selectAreaList = tempArr;
        this.order["provinceId"] = val[0].id;
        this.order["provinceName"] = val[0].text;
        this.order["cityId"] = val[1].id;
        this.order["cityName"] = val[1].text;
        this.order["districtId"] = val[2].id;
        this.order["districtName"] = val[2].text;
      }
      this.isShowArea = false;
    },
    //  获取商品详情
    async getGoodsDetail() {
      if (!this.goods || !this.goods.spuId) return;
      let res;
      if (!this.goodsStore[this.goods.spuId]) {
        res = await this.request.get("/api/scm/sale/scene/spu/detail", {
          sceneNo: this.sceneNo,
          spuId: this.goods.spuId
        });
        if (res) this.goodsStore[this.goods.spuId] = res;
      } else {
        res = this.goodsStore[this.goods.spuId];
      }
      let skuList = (res && res.skuList) || [];
      this.skuList = skuList.filter((item) => {
        return item.skuStock && item.skuStock.stock;
      });
      if (!this.skuList.length) {
        this.$toast({
          closeOnClick: true,
          duration: 3000,
          message: "非常抱歉，当前商品已经售罄",
          position: "bottom"
        });
      } else if (this.skuList.length == 1) {
        this.selectSkuId = this.skuList[0].skuId || "";
      }
      this.loading = false;
    },
    //  选择sku
    selectSku(id) {
      if (!id) return;
      this.selectSkuId = id == this.selectSkuId ? "" : id;
    },
    //  输入验证
    checkInput(item) {
      let tips = "",
        con = this.order[item];
      switch (item) {
        case "name":
          if (!con) tips = "请输入联系人";
          break;
        case "mobile":
          if (!con) tips = "请输入手机号";
          break;
        case "area":
          if (
            !this.order.provinceName ||
            !this.order.cityName ||
            !this.order.districtName
          )
            tips = "请选择所在地区";
          break;
        case "detailAddress":
          if (!con) tips = "请输入详细地址";
          break;
      }
      return tips;
    }
  }
};
</script>

<style lang="scss" scoped>
.show-dialog-enter-active,
.show-dialog-leave-active {
  transition: opacity 0.2s;
}
.show-dialog-enter,
.show-dialog-leave-to {
  opacity: 0;
}
// .pull-area-enter-active,
// .pull-area-leave-active {
//   transition: transform 0.2s;
// }
// .pull-area-enter,
// .pull-area-leave-to {
//   transform: translateY(100%);
// }
.mask {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#exchageDialog {
  .dialogWrap {
    width: 600px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 16px;
    padding: 0 0 30px 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 85%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &.disable {
      pointer-events: none;
    }
    .dialogScroll {
      flex: 1;
      overflow-y: auto;
      padding-right: 30px;
    }
    .goodsWrap {
      display: flex;
      align-items: flex-start;
      padding-bottom: 10px;
      .goodsImg {
        display: block;
        width: 130px;
        height: 130px;
        margin-right: 20px;
      }
      .goodsName {
        line-height: 40px;
        font-size: 26px;
        flex: 1;
      }
    }
    .goodsParams {
      margin: 10px 0 25px;
      font-size: 0;
      .aGoodsParam {
        display: inline-block;
        margin-right: 20px;
        margin-top: 10px;
        /* prettier-ignore */
        border: 1PX solid #eee;
        background: #eee;
        line-height: 28px;
        text-align: center;
        min-width: 68px;
        padding: 10px 25px;
        border-radius: 50px;
        font-size: 24px;
        &.cur {
          color: #ff583d;
          border-color: #ff583d;
          background: #fff;
        }
      }
    }
    .title {
      font-weight: bold;
      font-size: 30px;
      line-height: 87px;
      height: 87px;
      font-size: 36px;
    }
    .infoForm {
      padding: 4px 0 8px;
      .infoFormItem {
        display: flex;
        align-items: flex-start;
        margin-bottom: 23px;
        .txt {
          display: inline-block;
          width: 125px;
          line-height: 58px;
          font-size: 26px;
        }
        .inputWrap {
          flex: 1;
          min-width: 0px;
          .addrWrap {
            display: flex;
            justify-content: space-between;
            .addr {
              box-sizing: border-box;
              border: 1px solid #dadbdd;
              flex: 1;
              margin-bottom: 12px;
              color: #d2d2d2;
              font-size: 26px;
              padding-left: 30px;
              line-height: 56px;
              position: relative;
              transition: background 0.5s, color 0.5s;
              &.hastxt {
                color: #333;
                padding: 0 20px 0 10px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              &::after {
                content: "";
                display: block;
                position: absolute;
                border: 9px solid transparent;
                border-bottom: 0;
                border-top-color: #666;
                top: 22px;
                right: 10px;
              }
              & + .addr {
                margin-left: 11px;
              }
            }
            &.disabled .addr {
              background: #f1f1f1;
              color: #d2d2d2;
            }
          }
          .fromInput,
          .fromInput:focus {
            transition: background 0.5s, color 0.5s;
            display: block;
            width: 100%;
            box-sizing: border-box;
            height: 58px;
            border: 1px solid #dadbdd;
            border-radius: 0;
            padding: 0 15px 0 20px;
            font-size: 26px;
            &::placeholder {
              color: #d2d2d2;
            }
            &:disabled {
              background: #f1f1f1;
              color: #d2d2d2;
            }
          }
        }
      }
    }
    .btns {
      padding: 20px 0 4px;
      text-align: center;
      display: flex;
      justify-content: center;
      .btn {
        margin: 0 15px;
        height: 64px;
        width: 215px;
        /* prettier-ignore */
        border: 1PX solid #ff583d;
        border-radius: 10px;
        background: #fff;
        color: #ff583d;
        font-size: 28px;
        transition: opacity 0.5s;
        &.main {
          background: #ff583d;
          color: #fff;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          &::v-deep {
            .van-loading {
              display: inline-block;
              margin: 0 20px 0 0;
            }
          }
          &:disabled {
            opacity: 0.3;
          }
        }
      }
    }
  }
  &::v-deep {
    .van-loading {
      height: 30px;
      line-height: 30px;
      margin: 30px 0 35px;
    }
    .van-picker__toolbar {
      height: 90px;
      .van-picker__title {
        font-size: 32px;
        line-height: 90px;
      }
      .van-picker__cancel,
      .van-picker__confirm {
        font-size: 30px;
      }
    }
    .van-picker-column {
      font-size: 30px;
    }
  }
}
</style>
