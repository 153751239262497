<template>
  <div id="jxExchange" class="fixedMode">
    <div class="mainScroll">
      <img src="@/assets/activity/jxExchange/mainbg.png" class="mainBg" alt />
      <van-list
        class="goodsList"
        v-model="listIsLoading"
        :finished="loadFinised"
        @load="getGoodsList"
      >
        <!-- 空数据占位start -->
        <template v-if="!isListInit">
          <div class="aGoods loading" v-for="n in 2" :key="'aHotGoods' + n">
            <div class="goodsImg"></div>
            <p class="goodsName gapLoad">--</p>
            <div class="goodsInfo">
              <span class="saleSign gapHidden">--</span>
              <div class="goodsPrizeWrap gapLoad"></div>
            </div>
          </div>
        </template>
        <!-- 空数据占位end -->
        <div
          class="aGoods"
          v-for="item in goodsList"
          :key="item.spuNo"
          @click="toExchange(item)"
        >
          <div
            class="goodsImg"
            :class="{
              noImg: !item.spuSnapshotGallery || !item.spuSnapshotGallery.imgUrl
            }"
          >
            <img
              :alt="item.name"
              v-if="item.spuSnapshotGallery && item.spuSnapshotGallery.imgUrl"
              :src="item.spuSnapshotGallery && item.spuSnapshotGallery.imgUrl"
            />
          </div>
          <p class="goodsName">{{ item.name }}</p>
          <div class="goodsInfo">
            <span class="saleSign">专享折后价</span>
            <div class="goodsPrizeWrap">
              <span class="goodsPrize">￥{{ item.currentPrice }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>

    <exchageDialog
      :sceneNo="sceneNo"
      :isDialogShow="isExchageDialogShow"
      :goods="curGoods"
      @close="isExchageDialogShow = false"
    />
  </div>
</template>

<script>
import exchageDialog from "@/components/activity/jxExchage/exchageDialog.vue";

export default {
  name: "jxExchange",
  components: {
    exchageDialog
  },
  data() {
    return {
      sceneNo: this.$route.query.scence || "",
      curPage: 0, //  当前列表请求页数
      pageSize: 20, //  一次请求加载个数
      goodsList: [], //  商品列表
      curGoods: {}, //  当前选中的商品
      listIsLoading: false, //  列表是否正在加载
      isListInit: false, //  初始化标识
      loadFinised: false, //  列表全部加载完成标识
      isExchageDialogShow: false //  交换窗口
    };
  },
  methods: {
    //  获取兑换商品列表
    async getGoodsList() {
      this.curPage++;
      let res = await this.request.get("/api/scm/sale/scene/spu/list", {
        sceneNo: this.sceneNo, //  todo 测试数据
        page: this.curPage,
        size: this.pageSize
      });
      this.isListInit = true;
      this.listIsLoading = false;
      this.loadFinised = !res || res.length < this.pageSize; //  如果接口返回的数据小于分页数说明没有下一页了（接口没有返回总数 通过此方法来判断是否可以加载下一页）
      if (!!res && res.length) {
        this.goodsList = this.goodsList.concat(res);
      }
    },
    //  打开兑换面板
    toExchange(item) {
      this.curGoods = item || {};
      this.isExchageDialogShow = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.mainBg {
  width: 100%;
  display: block;
  height: 270px;
}
.goodsList {
  width: 690px;
  margin: 30px auto 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
  .aGoods {
    width: 330px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    border-radius: 12px;
    .goodsImg {
      height: 335px;
      position: relative;
    }
    .goodsName {
      margin: 10px 10px 10px 20px;
      height: 38px;
      line-height: 38px;
      font-size: 26px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .goodsInfo {
      padding: 0 20px 20px;
      font-size: 0;
      .returnSign {
        line-height: 38px;
        height: 38px;
        font-size: 20px;
        color: #888;
        background: #f2f4f5;
        border-radius: 5px;
        display: inline-block;
        padding: 0 15px;
        margin-bottom: 12px;
        &::after {
          content: "";
          display: block;
        }
      }
      .goodsPrizeWrap {
        margin-top: 10px;
        line-height: 34px;
        min-height: 34px;
        overflow: hidden;
        clear: both;
      }
      .goodsPrize {
        font-size: 30px;
        font-weight: bold;
        color: #ea3d39;
        float: left;
      }
      .buyNum {
        font-size: 22px;
        color: #999;
        float: right;
      }
    }
  }
  &::v-deep {
    .van-list__placeholder {
      width: 100%;
    }
    .van-list__loading {
      width: 100%;
      text-align: center;
    }
  }
}
</style>

